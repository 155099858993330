import * as React from "react"
import type { DropzoneOptions, FileRejection } from "react-dropzone"
import { useDropzone } from "react-dropzone"
import { FiFile, FiPlus, FiTrash } from "react-icons/fi"
import type { BoxProps } from "@chakra-ui/react"
import { Stack } from "@chakra-ui/react"
import {
  Box,
  Center,
  Flex,
  FormControl,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react"
import * as Sentry from "@sentry/nextjs"
import Compressor from "compressorjs"
import * as path from "path"

import type { CustomFile } from "lib/helpers/isValidFile"
import { DEFAULT_ACCEPTED_FILETYPES, isValidFile } from "lib/helpers/isValidFile"
import { useFormContext } from "lib/hooks/useForm"
import { useToast } from "lib/hooks/useToast"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { InputError } from "./InputError"
import { InputLabel } from "./InputLabel"

interface Props extends Omit<BoxProps, "onDrop"> {
  name: string
  label?: string
  isRequired?: boolean
  isDisabled?: boolean
  subLabel?: string
  dropzoneOptions?: Omit<DropzoneOptions, "multiple" | "onDrop">
  acceptAllFiletypes?: boolean
  customFiletypes?: string[]
}

export function MultiFileInput({
  name,
  label,
  subLabel,
  isDisabled,
  isRequired,
  dropzoneOptions,
  acceptAllFiletypes = false,
  customFiletypes,
  ...props
}: Props) {
  const {
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const bt = useBetterTranslation()
  const toast = useToast()
  const fieldError = errors?.[name]

  React.useEffect(() => {
    register(name)
  }, [register, name])

  const files = watch(name) as File[] | string[]

  const onDrop = React.useCallback(
    async (newFiles: CustomFile[], rejectedFiles: FileRejection[]) => {
      if (newFiles.length === 0) return

      const processedFiles: (CustomFile | Blob)[] = []

      await Promise.all(
        newFiles.map(async (file) => {
          const ext = file.path ? path.parse(file.path).ext : undefined

          if (!isValidFile(file, bt, toast, dropzoneOptions?.maxSize, acceptAllFiletypes, customFiletypes))
            return

          // Catch any other rejections
          if (rejectedFiles.length > 0) {
            toast({ status: "error", description: bt({ en: "Invalid file", nl: "Ongeldig bestand" }) })
            return
          }

          if (ext && DEFAULT_ACCEPTED_FILETYPES.images.includes(ext)) {
            await new Promise<void>((resolve, reject) => {
              new Compressor(file, {
                quality: 0.2, // not recommended to go below 0.6, but have tested and the quality of 0.2 should be good enough
                success(compressedFile) {
                  clearErrors(name)
                  processedFiles.push(compressedFile)
                  resolve()
                },
                error(e) {
                  Sentry.captureException(`MultiFileInput Image compression error: ${e}`)
                  console.log(e.message)
                  reject()
                },
              })
            })
          } else {
            clearErrors(name)
            processedFiles.push(file)
          }
        }),
      )
      setValue(name, [...files, ...processedFiles], { shouldDirty: true })
    },
    [toast, dropzoneOptions, files, name, setValue, clearErrors, bt, acceptAllFiletypes, customFiletypes],
  )

  const { getRootProps, getInputProps } = useDropzone({ ...dropzoneOptions, onDrop, multiple: true })

  const borderColor = useColorModeValue("gray.200", "gray.600")
  const bgColor = useColorModeValue("gray.50", "gray.700")
  const inputBgColor = useColorModeValue("white", "gray.800")
  const textColor = useColorModeValue("gray.800", "gray.200")

  return (
    <FormControl isRequired={isRequired} isInvalid={!!fieldError}>
      <InputLabel label={label} subLabel={subLabel} name={name} />
      <Center
        w="100%"
        h="300px"
        rounded="sm"
        overflow="hidden"
        border="1px solid"
        borderColor={!!fieldError ? "red.500" : borderColor}
        textAlign="center"
        color="gray.500"
        bg={bgColor}
        {...props}
      >
        {files && files.length > 0 ? (
          <Stack p={4} h="100%" w="100%" overflowY="scroll">
            <Box {...getRootProps()} outline={isDisabled ? "none" : undefined} w="100%">
              <input disabled={isDisabled} {...getInputProps()} />
              <IconButton
                aria-label="add another upload"
                icon={<Box as={FiPlus} boxSize="22px" />}
                size="sm"
                colorScheme="pink"
              />
            </Box>
            <Stack>
              {files.map((file, i) => (
                <Flex
                  key={i}
                  p={2}
                  bg={inputBgColor}
                  align="center"
                  marginLeft={0}
                  justifyContent="space-between"
                  boxShadow="sm"
                  borderRadius="sm"
                  w="100%"
                >
                  <Flex alignItems="center" overflow="hidden">
                    <Box as={FiFile} boxSize="16px" color="pink.500" mx={2} />
                    <Text fontSize="sm" noOfLines={1} color={textColor} pr={2} textAlign="left">
                      {typeof file === "string" ? file.split("/").pop() : file?.name}
                    </Text>
                  </Flex>

                  <Tooltip
                    label={bt({ en: "Remove file", nl: "Verwijder bestand" })}
                    aria-label="Remove file"
                  >
                    <IconButton
                      aria-label="remove file"
                      icon={<Box as={FiTrash} />}
                      onClick={() =>
                        setValue(
                          name,
                          (files as Array<any>).filter(
                            (_, i) =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              i !== files.indexOf(file),
                          ),
                          { shouldDirty: true },
                        )
                      }
                      colorScheme="red"
                      size="sm"
                      borderRadius="sm"
                    />
                  </Tooltip>
                </Flex>
              ))}
            </Stack>
          </Stack>
        ) : (
          <Center
            {...getRootProps()}
            _hover={{ opacity: 0.9 }}
            outline={isDisabled ? "none" : undefined}
            cursor={isDisabled ? "default" : "pointer"}
            color="gray.500"
            w="100%"
            h="100%"
          >
            <input disabled={isDisabled} {...getInputProps()} />
            {bt({
              en: "Drag or click here to upload file",
              nl: "Sleep of klik hier om een bestand te uploaden",
            })}
          </Center>
        )}
      </Center>
      <InputError error={fieldError} />
    </FormControl>
  )
}
