import * as React from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { HStack, IconButton, SelectProps } from "@chakra-ui/react"
import { FormControl, Select as CSelect } from "@chakra-ui/react"

import { InputError } from "./InputError"
import { InputLabel } from "./InputLabel"
import { FiX } from "react-icons/fi"

interface Props extends SelectProps {
  name: string
  options: any[]
  label?: string
  subLabel?: string
  isClearable?: boolean
}

export function Select({ label, subLabel, placeholder, isClearable = false, ...props }: Props) {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()
  const fieldError = errors?.[props.name]
  const value = useWatch({ name: props.name })
  return (
    <FormControl isInvalid={!!fieldError} isRequired={props.isRequired}>
      <InputLabel label={label} subLabel={subLabel} name={props.name} />
      <HStack spacing={1}>
        <CSelect {...register(props.name)} {...props} mb={0}>
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {props.options.map((option, index) => (
            <option
              key={index}
              value={typeof option === "string" || typeof option === "number" ? option : option.value}
            >
              {typeof option === "string" || typeof option === "number" ? option : option.label}
            </option>
          ))}
        </CSelect>
        {isClearable && value && (
          <IconButton
            aria-label="clear input"
            variant="ghost"
            size="sm"
            icon={<FiX size={18} />}
            onClick={() => setValue(props.name, null, { shouldDirty: true })}
          />
        )}
      </HStack>
      <InputError error={fieldError} />
    </FormControl>
  )
}
